import classNames from "classnames"
import React from "react"

import { Color } from "../../../../constants/V2/color"
import DownloadAppButton from "../../../elements/V2/Buttons/DownloadAppButton"
import PillButton from "../../../elements/V2/Buttons/PillButton"
import GatsbyStoryblokImage from "../../../elements/V2/GatsbyStoryblokImage"
import Typography from "../../../elements/V2/Typography"

import { useScrollAnimateSelf } from "@hooks/V2/useScrollAnimate"

type Props = {
  textColor: Color
  titleText: string
  bodyText?: string
  imageUrl: string
  imageAlt: string
  buttonText: string
  buttonLink: string
  showDownloadAppButton: boolean
  buttonBackgroundColor: Color
  contentAlignment: "left" | "center"
}

const RichMedia = ({
  textColor,
  titleText,
  bodyText,
  imageUrl,
  imageAlt,
  buttonText,
  buttonLink,
  showDownloadAppButton = false,
  buttonBackgroundColor,
  contentAlignment,
  ...props
}: Props) => {
  const { animationRefCallback } = useScrollAnimateSelf(true)

  return (
    <div
      ref={animationRefCallback}
      className={classNames(
        "relative col-span-full inline-block h-full w-full"
      )}
      {...props}
    >
      <div className="absolute left-0 top-0 h-full w-full">
        <GatsbyStoryblokImage
          image={imageUrl}
          alt={imageAlt || "Rich media background image"}
          loadType="eager"
          quality={80}
          aspectRatio="fullWidth"
          rounded
          className="h-full w-auto"
        />
      </div>

      <div
        className={classNames(
          "relative flex h-full w-full flex-col",
          {
            "items-center justify-center px-24-v2 py-80-v2 md-v2:py-164-v2 lg-v2:py-[196px]":
              contentAlignment === "center",
          },
          {
            "md-v2:p32p-v2 items-start justify-end p-24-v2 pt-[138px] md-v2:pt-[260px] lg-v2:p-48-v2 lg-v2:pt-[340px]":
              contentAlignment === "left",
          }
        )}
      >
        <Typography
          text={titleText}
          font="grotesk"
          weight="medium"
          size="h2"
          color={textColor}
          className={classNames("mb-8-v2", {
            "text-center": contentAlignment === "center",
          })}
          element="h2"
        />
        {bodyText && (
          <Typography
            text={bodyText}
            font="grotesk"
            weight="book"
            size="lead-md"
            color={textColor}
            className={classNames("mb-24-v2 lg-v2:mb-32-v2", {
              "text-center": contentAlignment === "center",
            })}
          />
        )}

        {showDownloadAppButton ? (
          <DownloadAppButton text={buttonText} color={buttonBackgroundColor} />
        ) : (
          <PillButton
            size="large"
            style="solid"
            text={buttonText}
            color={buttonBackgroundColor}
            linkUrl={buttonLink}
          />
        )}
      </div>
    </div>
  )
}

export default RichMedia
